import { Box, HStack, Text } from '@chakra-ui/react';
import SubcategoryAvatar from './SubcategoryAvatar';
import { translate } from '../services/languageService';

export default function SectionDetail({ category, asset, subcategory, lang }) {
  return (
    <HStack w="full" h="full" spacing="6">
      <SubcategoryAvatar subcategory={subcategory} />
      <Box w="100%">
        <Text fontWeight="semibold">
          {translate(category.name, lang)}
          {' - '}
          {asset.name}
        </Text>
      </Box>
    </HStack>
  );
}
