export const LOCALES = {
  DEFAULT: 'default',
  ENGLISH: 'en',
  SPANISH: 'es',
  DUTCH: 'nl',
};

export const createSelectableLocales = () => {
  const result = [
    { locale: LOCALES.DEFAULT, label: 'common.default' },
    { locale: LOCALES.ENGLISH, label: 'English' },
    { locale: LOCALES.SPANISH, label: 'Español' },
    { locale: LOCALES.DUTCH, label: 'Nederlands' },
  ];
  return result;
};

export const getValidLocale = locale => {
  if (!locale) {
    return LOCALES.DEFAULT;
  }

  if (!Object.values(LOCALES).includes(locale)) {
    return LOCALES.DEFAULT;
  }

  return locale;
};
