import { createContext, useContext } from 'react';
import { useSettings } from '../hooks/useSettings';
import { useIdentityContext } from './IdentityContext';

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const { user } = useIdentityContext();
  const settings = useSettings(user);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettingsContext = () => {
  return useContext(SettingsContext);
};

export { useSettingsContext, SettingsProvider };
