import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdTranslate } from 'react-icons/md';
import { LAYOUTMODES } from '../../hooks/useLayoutMode';
import DropDownSwitcher from './DropDownSwitcher';
import VStackSwitcher from './VStackSwitcher';
import MenuGroupSwitcher from './MenuGroupSwitcher';
import { useIdentityContext } from '../../contexts/IdentityContext';
import { useSettingsContext } from '../../contexts/SettingsContext';

export default function LanguageSwitcher({ layoutMode = LAYOUTMODES.DESKTOP }) {
  const { locales, setLocale } = useSettingsContext();
  const { t } = useTranslation('translation');
  const { isAuthenticated } = useIdentityContext();

  const items = locales.map(item => {
    return {
      key: item.label,
      label: t(item.label),
      onClick: () => setLocale(item.locale),
    };
  });
  const props = {
    icon: MdTranslate,
    title: t('languageSwitcher.language'),
    items: items,
  };

  if (layoutMode === LAYOUTMODES.MOBILE) {
    return <VStackSwitcher {...props} />;
  }
  if (isAuthenticated) {
    return <MenuGroupSwitcher {...props} />;
  }
  return <DropDownSwitcher {...props} />;
}
