import { Box, Checkbox, HStack, Icon, Tag, Text } from '@chakra-ui/react';
import { MdStarBorder, MdStar } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

export default function AssetListItem({ item, size, onFavoriteToggle }) {
  const labelFactory = tag => {
    return (
      <Tag mr="1" mb="1" key={tag}>
        {tag}
      </Tag>
    );
  };

  return (
    <HStack w="full" h="full" ml="1">
      <Box w="5%">
        <Checkbox mt="1" />
      </Box>
      <Box w="5%">
        <Icon
          mt="1"
          w={5}
          h={5}
          aria-label="Favorite"
          as={item.isFavorite ? MdStar : MdStarBorder}
          onClick={onFavoriteToggle}
        />
      </Box>
      <Box w="50%" as={NavLink} to={`${item.id}`}>
        <Text fontSize={size}>{item.name}</Text>
      </Box>
      <Box w="40%">{item.labels.map(labelFactory)}</Box>
    </HStack>
  );
}
