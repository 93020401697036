import React from 'react';
import { useTranslation } from 'react-i18next';
import { LAYOUTMODES } from '../../hooks/useLayoutMode';
import DropDownSwitcher from './DropDownSwitcher';
import VStackSwitcher from './VStackSwitcher';
import MenuGroupSwitcher from './MenuGroupSwitcher';
import { useIdentityContext } from '../../contexts/IdentityContext';
import { useSettingsContext } from '../../contexts/SettingsContext';

export default function ThemeSwitcher({ layoutMode = LAYOUTMODES.DESKTOP }) {
  const { isAuthenticated } = useIdentityContext();
  const { currentTheme, themes, setTheme } = useSettingsContext();
  const { t } = useTranslation('translation');

  const items = themes.map(item => {
    return {
      key: item.label,
      label: t(item.label),
      icon: item.icon,
      onClick: () => setTheme(item.name),
    };
  });

  const props = {
    icon: currentTheme.icon,
    title: t('themeSwitcher.theme'),
    items: items,
  };

  if (layoutMode === LAYOUTMODES.MOBILE) {
    return <VStackSwitcher {...props} />;
  }

  if (isAuthenticated) {
    return <MenuGroupSwitcher {...props} />;
  }

  return <DropDownSwitcher {...props} />;
}
