import NotFoundPage from './pages/NotFoundPage';
import MaterialDetail from './MaterialDetail';
import SectionDetail from './SectionDetail';
import RebarMeshesDetail from './RebarMeshesDetail';
import ConnectionElementsDetail from './ConnectionElementsDetail';
import { CATEGORY_PATH } from '../services/categoryPathService';

export default function DetailPageContent({
  category,
  subcategory,
  asset,
  lang,
}) {
  const props = { category, subcategory, asset, lang };

  const selectDetailPage = (categoryPath, subcategoryPath, props) => {
    switch (categoryPath) {
      case CATEGORY_PATH.MATERIALS:
        return <MaterialDetail {...props} />;
      case CATEGORY_PATH.SECTIONS:
        return <SectionDetail {...props} />;
      case CATEGORY_PATH.REBAR_MESHES:
        return <RebarMeshesDetail {...props} />;
      case CATEGORY_PATH.CONNECTION_ELEMENTS:
        return <ConnectionElementsDetail {...props} />;
      default:
        return <NotFoundPage />;
    }
  };

  return selectDetailPage(category.path, subcategory.path, props);
}
