import { createContext, useContext } from 'react';
import { useIdentity } from '../hooks/useIdentity';

const IdentityContext = createContext();

const IdentityProvider = ({ children, openDialog }) => {
  const identity = useIdentity(openDialog);
  return (
    <IdentityContext.Provider value={identity}>
      {children}
    </IdentityContext.Provider>
  );
};

const useIdentityContext = () => {
  return useContext(IdentityContext);
};

export { useIdentityContext, IdentityProvider };
