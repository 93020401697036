import {
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';
import { useIdentityContext } from '../../contexts/IdentityContext';
import BreadcrumbDesktop from './BreadcrumbDesktop';
import IdentityDisplayDesktop from './IdentityDisplayDesktop';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeSwitcher from './ThemeSwitcher';

export default function HeaderDesktop() {
  const { isAuthenticated } = useIdentityContext();

  if (isAuthenticated) {
    return (
      <Flex w="full" h="full" m="3" gridColumnGap="12px">
        <BreadcrumbDesktop />
        <Spacer />
        <Menu fontsize="md" size="md" color="current">
          <MenuButton as={Button} variant="unstyled">
            <Center>
              <FaCog size="24" />
            </Center>
          </MenuButton>
          <MenuList fontSize="16px" px="3">
            <ThemeSwitcher />
            <MenuDivider mt="3" mb="4" />
            <LanguageSwitcher />
          </MenuList>
        </Menu>
        <IdentityDisplayDesktop />
      </Flex>
    );
  }

  return (
    <Flex w="full" h="full" m="3" gridColumnGap="12px" justifyContent="end">
      <ThemeSwitcher />
      <LanguageSwitcher />
      <IdentityDisplayDesktop />
    </Flex>
  );
}
