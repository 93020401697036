import {
  Button,
  ButtonGroup,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';

export default function VStackSwitcher({ icon, title, items }) {
  const LeftIcon = icon;
  const buttonFactory = item => {
    const props = {
      key: item.key,
      onClick: item.onClick,
    };
    if (item.icon) {
      props.leftIcon = <item.icon />;
    }
    return (
      <Button justifyContent="left" {...props}>
        {item.label}
      </Button>
    );
  };
  return (
    <VStack alignItems="left">
      <HStack mb="1">
        <Icon as={LeftIcon} />
        <Text fontSize="lg">{title}</Text>
      </HStack>
      <ButtonGroup spacing="0" variant="ghost" flexDirection="column">
        {items.map(buttonFactory)}
      </ButtonGroup>
    </VStack>
  );
}
