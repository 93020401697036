import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { useIdentityContext } from '../../contexts/IdentityContext';
import Profile from './Profile';

export default function IdentityDisplayDesktop() {
  const { isAuthenticated, login, logout, user } = useIdentityContext();
  const { t } = useTranslation('translation');

  if (isAuthenticated) {
    return (
      <Menu fontsize="md" size="md" color="current">
        <MenuButton as={Button} variant="unstyled">
          <Avatar size="sm" name={user.name} src={user.picture} m="1" />
        </MenuButton>
        <MenuList fontSize="16px" px="3">
          <Profile user={user} m="3" />
          <MenuItem icon={<FiLogOut />} onClick={logout}>
            {t('identityDisplay.logout')}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <Button leftIcon={<FiLogIn />} onClick={login}>
      {t('identityDisplay.login')}
    </Button>
  );
}
