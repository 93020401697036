import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Suspense } from 'react/cjs/react.production.min';
import App from './components/App';
import LocalizationScript from './components/LocalizationScript';
import reportWebVitals from './services/reportWebVitals';
import * as serviceWorker from './services/serviceWorker';
import { BrowserRouter } from 'react-router-dom';

// Render react
ReactDOM.render(
  <Suspense fallback="Loading...">
    <StrictMode>
      <ColorModeScript />
      <LocalizationScript />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
