import { useRef } from 'react';
import {
  Avatar,
  useColorModeValue,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Button,
  VStack,
  HStack,
  Text,
  ButtonGroup,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import lightLogo from './../../images/buildsoft-small-logo.svg';
import darkLogo from './../../images/buildsoft-small-logo-dark.svg';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { translate } from '../../services/languageService';
import { useTranslation } from 'react-i18next';

export default function BreadcrumbDesktop() {
  const { breadcrumbs } = useBreadcrumbs();
  const logo = useColorModeValue(lightLogo, darkLogo);
  const { t, i18n } = useTranslation('translation');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const itemFactory = item => {
    return (
      <Button
        key={item.name.default}
        as={NavLink}
        to={`/${item.link}`}
        justifyContent="left"
      >
        {translate(item.name, i18n.language)}
      </Button>
    );
  };

  return (
    <>
      <Avatar
        bg="transparent"
        onClick={onOpen}
        src={logo}
        size="sm"
        borderRadius="4px"
      />
      <Drawer
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>BuildSoft Assets</DrawerHeader>
          <DrawerBody>
            <VStack alignItems="left">
              <HStack mb="1">
                <Text fontSize="lg">{t('breadcrumb.title')}</Text>
              </HStack>
              <ButtonGroup spacing="0" variant="ghost" flexDirection="column">
                <Button as={NavLink} to="/" justifyContent="left">
                  {t('breadcrumb.home')}
                </Button>
                {breadcrumbs.map(itemFactory)}
              </ButtonGroup>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
