const mergeSubcategoriesIntoCategory = (category, subcategories) => {
  category.relationships.subcategories.data =
    category.relationships.subcategories.data.map(ri =>
      subcategories.find(sc => sc.id === ri.id && sc.type === ri.type)
    );
  return flattenResource(category);
};

const flattenResource = resource => {
  const { attributes, relationships, ...otherFields } = resource;
  if (relationships) {
    let newRelationships = {};
    for (const [key, value] of Object.entries(relationships)) {
      newRelationships[key] = value.data;
    }
    return { ...otherFields, ...attributes, ...newRelationships };
  }
  return { ...otherFields, ...attributes };
};

export const mergeIncludedSubcategoriesIntoCategories = ({
  data,
  included,
}) => {
  const flattenedSubcategories = included.map(sc => flattenResource(sc));
  return data.map(c =>
    mergeSubcategoriesIntoCategory(c, flattenedSubcategories)
  );
};

export const mergeIncludedSubcategoriesIntoCategory = ({ data, included }) => {
  const flattenedSubcategories = included.map(sc => flattenResource(sc));
  return mergeSubcategoriesIntoCategory(data, flattenedSubcategories);
};
