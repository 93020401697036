import {
  VStack,
  StackDivider,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLayoutMode } from '../hooks/useLayoutMode';
import { REQUEST_STATUS } from '../hooks/useRestRequest';
import CategoryListItem from './CategoryListItem';
import NotFoundPage from './pages/NotFoundPage';
import LoadingPage from './pages/LoadingPage';
import { useCategoriesContext } from '../contexts/CategoriesContext';

export default function CategoryList({ width, maxW }) {
  const { mode } = useLayoutMode();
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const smallSize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const size = useBreakpointValue({ base: 'sm', md: 'md' });
  const { i18n } = useTranslation('translation');
  const lang = i18n.language;
  const { categories, requestStatus } = useCategoriesContext();

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage />;
  }

  if (categories === undefined) {
    return <NotFoundPage />;
  }

  return (
    <VStack
      borderWidth="1px"
      rounded={'2xl'}
      bg={categoriesBg}
      w={width}
      maxW={maxW}
      boxShadow={'lg'}
      justify={{ base: 'center' }}
      align={{ base: 'center', md: 'center' }}
      spacing="0"
      display="flex"
      direction={['column', 'row']}
      divider={<StackDivider />}
    >
      {categories.map(category => {
        return (
          <CategoryListItem
            key={category.id}
            category={category}
            lang={lang}
            mode={mode}
            size={size}
            smallSize={smallSize}
          />
        );
      })}
    </VStack>
  );
}
