import { AvatarGroup, Box, Tooltip } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { translate } from '../services/languageService';
import SubcategoryAvatar from './SubcategoryAvatar';

export default function QuickAccess({ category, lang, size }) {
  return (
    <Box p={4}>
      <AvatarGroup max={4} size={size} fontSize={{ base: 'xs', md: 'md' }}>
        {category.subcategories?.map(subcategory => {
          return (
            <Tooltip
              key={subcategory.id}
              label={translate(subcategory.name, lang)}
              placement="bottom"
            >
              <SubcategoryAvatar
                as={NavLink}
                to={`${category.path}/${subcategory.path}`}
                key={subcategory.id}
                marginStart={4}
                size={size}
                subcategory={subcategory}
              />
            </Tooltip>
          );
        })}
      </AvatarGroup>
    </Box>
  );
}
