import { Avatar, useColorMode } from '@chakra-ui/react';
import { getColorModePicture } from '../services/colorModeService';
import lightLogo from './../images/category.svg';
import darkLogo from './../images/category-dark.svg';

export default function CategoryAvatar(props) {
  const { category, ...otherProps } = props;
  const { colorMode } = useColorMode();
  const src = getColorModePicture(colorMode, category, lightLogo, darkLogo);

  return (
    <Avatar
      bg="transparent"
      borderRadius="8px"
      src={src}
      alt={category?.name?.default}
      ignoreFallback={true}
      {...otherProps}
    />
  );
}
