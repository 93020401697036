import { Button } from '@chakra-ui/react';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { useIdentityContext } from '../../contexts/IdentityContext';
import { useTranslation } from 'react-i18next';
import Profile from './Profile';

export default function IdentityDisplayMobile() {
  const { isAuthenticated, login, logout, user } = useIdentityContext();
  const { t } = useTranslation('translation');

  if (isAuthenticated) {
    return (
      <>
        <Profile user={user} mb={3} />
        <Button
          justifyContent="left"
          leftIcon={<FiLogOut />}
          mt="2"
          onClick={logout}
          variant="ghost"
          w="100%"
        >
          {t('identityDisplay.logout')}
        </Button>
      </>
    );
  }
  return (
    <Button
      leftIcon={<FiLogIn />}
      onClick={login}
      justifyContent="left"
      w="full"
      variant="ghost"
    >
      {t('identityDisplay.login')}
    </Button>
  );
}
