import { Box, useColorModeValue, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { REQUEST_STATUS, useRestRequest } from '../../hooks/useRestRequest';
import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';
import { useTranslation } from 'react-i18next';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { useIdentityContext } from '../../contexts/IdentityContext';
import DetailPageContent from '../DetailPageContent';
import { SubcategoryUrl } from '../../services/urlService';

export default function DetailPage({ minH }) {
  const { i18n } = useTranslation('translation');
  const { categoryPath, subcategoryPath, itemId } = useParams();
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const lang = i18n.language;
  const { getCategory, requestStatus } = useCategoriesContext();
  const category = getCategory(categoryPath);

  const subcategory = category?.subcategories.find(
    sub => sub.path === subcategoryPath
  );

  const { authHeaders } = useIdentityContext();
  const { data: subcategoryItems, requestStatus: subcategoryRequestStatus } =
    useRestRequest(
      SubcategoryUrl(category?.path, subcategory?.id),
      authHeaders
    );

  if (
    requestStatus === REQUEST_STATUS.LOADING ||
    subcategoryRequestStatus === REQUEST_STATUS.LOADING
  ) {
    return <LoadingPage minH={minH} />;
  }

  const asset = subcategoryItems?.find(item => item.id === itemId);

  if (
    category === undefined ||
    subcategory === undefined ||
    asset === undefined
  ) {
    return <NotFoundPage minH={minH} />;
  }

  return (
    <Box
      display="flex"
      alignItems="baseline"
      minH={minH}
      p="5"
      justifyContent="center"
    >
      <VStack
        bg={categoriesBg}
        w={'950px'}
        maxW={'1100px'}
        boxShadow={'lg'}
        justify={{ base: 'center' }}
        align={{ base: 'center', md: 'center' }}
        display="flex"
        direction={['column', 'row']}
        borderWidth="1px"
        rounded={'2xl'}
        p="4"
        spacing="4"
      >
        <DetailPageContent
          category={category}
          subcategory={subcategory}
          asset={asset}
          lang={lang}
        />
      </VStack>
    </Box>
  );
}
