import React from 'react';
import { useIdentityContext } from '../contexts/IdentityContext';
import NotAuthenticatedErrorPage from './pages/NotAuthenticatedErrorPage';

export default function IfAuthenticated({ minH, page }) {
  const { isAuthenticated } = useIdentityContext();

  if (!isAuthenticated) {
    return <NotAuthenticatedErrorPage minH={minH} />;
  }

  const PageComponent = page;
  return <PageComponent minH={minH} />;
}
