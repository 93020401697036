import { Box, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from '../../hooks/useRestRequest';
import { useAssetsFilter } from '../../hooks/useAssetsFilter';
import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';
import SubcategoryPageContent from '../SubcategoryPageContent';
import { useCategoriesContext } from '../../contexts/CategoriesContext';

export default function SubcategoryPage({ minH }) {
  const { i18n } = useTranslation('translation');
  const { categoryPath, subcategoryPath } = useParams();
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const resizeSubcategoryAvatar = useBreakpointValue({ base: 'md', md: 'lg' });
  const filter = useAssetsFilter();
  const lang = i18n.language;
  const { getCategory, requestStatus } = useCategoriesContext();

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage minH={minH} />;
  }

  const category = getCategory(categoryPath);
  const subcategory = category?.subcategories.find(
    sub => sub.path === subcategoryPath
  );

  if (category === undefined || subcategory === undefined) {
    return <NotFoundPage minH={minH} />;
  }

  return (
    <Box
      display="flex"
      alignItems="baseline"
      minH={minH}
      p="5"
      justifyContent="center"
    >
      <SubcategoryPageContent
        bg={categoriesBg}
        category={category}
        categoryPath={categoryPath}
        filter={filter}
        lang={lang}
        subcategory={subcategory}
        size={resizeSubcategoryAvatar}
      />
    </Box>
  );
}
