import { Stack } from '@chakra-ui/react';
import { LAYOUTMODES, useLayoutMode } from '../../hooks/useLayoutMode';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

export default function Header({ minH }) {
  var { mode } = useLayoutMode();

  const determineContent = () => {
    switch (mode) {
      case LAYOUTMODES.MOBILE:
        return <HeaderMobile />;
      default:
        return <HeaderDesktop />;
    }
  };

  return (
    <Stack w="100%" direction="row" minH={minH}>
      {determineContent()}
    </Stack>
  );
}
