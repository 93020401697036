import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { translate } from '../services/languageService';
import { LAYOUTMODES } from '../hooks/useLayoutMode';
import CategoryAvatar from './CategoryAvatar';
import QuickAccess from './QuickAccess';

export default function CategoryListItem({
  category,
  lang,
  mode,
  size,
  smallSize,
}) {
  const quickAccess = () => {
    if (mode === LAYOUTMODES.DESKTOP) {
      return <QuickAccess category={category} lang={lang} size={size} />;
    }
  };

  return (
    <Box w="100%" key={category.id}>
      <HStack rounded={'2xl'} w={'full'} spacing="6">
        <Box w="100%">
          <NavLink to={`${category.path}`}>
            <HStack p={4}>
              <CategoryAvatar category={category} size={size} />
              <Stack spacing="1">
                <Text fontWeight="semibold" fontSize={size}>
                  {translate(category.name, lang)}
                </Text>
                <Text fontSize={smallSize} color={'gray.500'} noOfLines={1}>
                  {category.description === undefined
                    ? '\u00A0'
                    : translate(category.description, lang)}
                </Text>
              </Stack>
            </HStack>
          </NavLink>
        </Box>
        {quickAccess()}
      </HStack>
    </Box>
  );
}
