import { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoMdBusiness } from 'react-icons/io';
import { FiUsers } from 'react-icons/fi';

function ProfileUser({ user }) {
  return (
    <HStack
      w="full"
      h="full"
      spacing={0}
      align="flex-start"
      alignItems="stretch"
    >
      <Avatar size="lg" mr="3" name={user.name} src={user.picture} />
      <VStack w="full" align="left">
        <Text fontSize="lg">
          <strong>{user.name}</strong>
        </Text>
        <Text>{user.email}</Text>
      </VStack>
    </HStack>
  );
}

function MemberDisplay({ name, picture, members }) {
  return (
    <HStack
      w="full"
      h="full"
      spacing={0}
      align="flex-start"
      alignItems="stretch"
    >
      <Avatar size="md" ml="3" mr="3" name={name} src={picture} />
      <VStack spacing="0" align="flex-start">
        <Text fontSize="md">
          <strong>{name}</strong>
        </Text>
        <AvatarGroup size="xs" max={6} spacing={-2}>
          {members
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
            .map(m => {
              return <Avatar key={m.id} name={m.name} src={m.picture} />;
            })}
        </AvatarGroup>
      </VStack>
    </HStack>
  );
}

function ProfileOrganizations({ organizations, title }) {
  const [show, setShow] = useState(false);

  if (!organizations) {
    return null;
  }

  if (organizations.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        isFullWidth
        variant="ghost"
        leftIcon={<IoMdBusiness size={18} />}
        onClick={() => setShow(!show)}
        justifyContent="left"
      >
        {title}
      </Button>
      {show ? (
        <VStack w="full" align="left">
          {organizations.map(org => {
            return (
              <MemberDisplay
                key={org.id}
                name={org.name}
                picture={org.picture}
                members={org.otherMembers}
              />
            );
          })}
        </VStack>
      ) : null}
    </>
  );
}

function ProfileTeams({ teams, title }) {
  const [show, setShow] = useState(false);

  if (!teams) {
    return null;
  }

  if (teams.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        isFullWidth
        variant="ghost"
        leftIcon={<FiUsers size={18} />}
        onClick={() => setShow(!show)}
        justifyContent="left"
      >
        {title}
      </Button>
      {show ? (
        <VStack w="full" align="left">
          {teams.map(team => {
            return (
              <MemberDisplay
                key={team.id}
                name={team.name}
                picture={team.picture}
                members={team.otherMembers}
              />
            );
          })}
        </VStack>
      ) : null}
    </>
  );
}

export default function Profile(props) {
  const { user } = props;
  const { t } = useTranslation('translation');
  return (
    <VStack {...props}>
      <ProfileUser user={user} />
      <ProfileOrganizations
        organizations={user.organizations}
        title={t('common.organizations')}
      />
      <ProfileTeams teams={user.teams} title={t('common.teams')} />
    </VStack>
  );
}
