import { useState } from 'react';

export const useAssetsFilter = () => {
  const [show, setShow] = useState(true);
  const [filters, setFilters] = useState([]);

  const createFilter = (id, search, tags, isFavorite) => {
    const result = {
      id,
      search: search ?? '',
      tags: tags ?? [],
      isFavorite: isFavorite ?? false,
    };
    result.isEmpty = isEmptyFilter(result);
    return result;
  };

  const isEmptyFilter = filter => {
    return !filter.search && filter.tags.length === 0 && !filter.isFavorite;
  };

  const getFilter = id => {
    return filters.find(f => f.id === id) ?? createFilter(id);
  };
  const updateFilter = (id, filter) => {
    if (isEmptyFilter(filter)) {
      clearFilter(id);
      return;
    }
    const newFilters = [filter, ...filters.filter(f => f.id !== id)];
    setFilters(newFilters);
  };
  const updateFilterSearch = (id, search) => {
    const currentFilter = getFilter(id);
    const newFilter = createFilter(
      id,
      search,
      currentFilter?.tags,
      currentFilter?.isFavorite
    );
    updateFilter(id, newFilter);
  };
  const updateFilterTags = (id, tags) => {
    const currentFilter = getFilter(id);
    const newFilter = createFilter(
      id,
      currentFilter?.search,
      tags,
      currentFilter?.isFavorite
    );
    updateFilter(id, newFilter);
  };
  const updateFilterIsFavorite = (id, isFavorite) => {
    const currentFilter = getFilter(id);
    const newFilter = createFilter(
      id,
      currentFilter?.search,
      currentFilter?.tags,
      isFavorite
    );
    updateFilter(id, newFilter);
  };
  const clearFilter = id => {
    const newFilters = filters.filter(f => f.id !== id);
    setFilters(newFilters);
  };

  return {
    show,
    setShow,
    getFilter,
    updateFilterSearch,
    updateFilterTags,
    updateFilterIsFavorite,
    clearFilter,
  };
};
