import { useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languagedetector from 'i18next-browser-languagedetector';
import { LANGUAGES } from '../services/languageService';
import HttpApi from 'i18next-http-backend';

const detection = {
  order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],

  //keys to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
};

export default function LocalizationScript({ resources = undefined }) {
  const supportedLngs = Object.values(LANGUAGES);

  const options = {
    fallbackLng: LANGUAGES.ENGLISH,
    supportedLngs: supportedLngs,
    interpolation: {
      escapeValue: false,
    },
    detection,
  };

  if (resources) {
    options.resources = resources;
  } else {
    options.backend = {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    };
    i18n.use(HttpApi);
  }

  i18n.use(initReactI18next).use(languagedetector).init(options);
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  });
  return null;
}
