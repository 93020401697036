import { useState } from 'react';
import {
  sortFieldAscending,
  sortFieldDescending,
} from '../services/sortingService';

export const useSortByField = initialField => {
  const [currentField, setCurrentField] = useState(initialField);
  const [sortAscending, setSortAscending] = useState(true);

  const sortDataByField = (data, setData, property) => {
    if (currentField !== property) {
      setCurrentField(property);
      setSortAscending(true);
      setData(sortFieldAscending(data, property));
      return;
    }

    if (!sortAscending) {
      setSortAscending(true);
      setData(sortFieldAscending(data, property));
      return;
    }

    setSortAscending(false);
    setData(sortFieldDescending(data, property));
  };

  return { currentField, sortAscending, sortDataByField };
};
