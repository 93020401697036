import { VStack, useColorModeValue, StackDivider } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { translate } from '../services/languageService';
import SubcategoryListItem from './SubcategoryListItem';

export default function SubcategoryList({ category, lang }) {
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const resizeElements = useBreakpointValue({ base: 'sm', md: 'md' });

  return (
    <VStack
      borderWidth="1px"
      rounded={'2xl'}
      bg={categoriesBg}
      boxShadow={'lg'}
      justify={{ base: 'center' }}
      align={{ base: 'center', md: 'center' }}
      spacing="0"
      display="flex"
      w="full"
      h="full"
      direction={['column', 'row']}
      divider={<StackDivider />}
    >
      {category.subcategories
        ?.sort((a, b) => {
          const aName = translate(a.name, lang);
          const bName = translate(b.name, lang);
          return aName > bName ? 1 : aName < bName ? -1 : 0;
        })
        .map(subcategory => {
          return (
            <SubcategoryListItem
              key={subcategory.id}
              category={category}
              subcategory={subcategory}
              lang={lang}
              size={resizeElements}
            />
          );
        })}
    </VStack>
  );
}
