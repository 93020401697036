import { forwardRef } from 'react';
import { Avatar, useColorMode } from '@chakra-ui/react';
import { getColorModePicture } from '../services/colorModeService';
import lightLogo from './../images/subcategory.svg';
import darkLogo from './../images/subcategory-dark.svg';

const SubcategoryAvatar = forwardRef((props, ref) => {
  const { subcategory, ...otherProps } = props;
  const { colorMode } = useColorMode();
  const src = getColorModePicture(colorMode, subcategory, lightLogo, darkLogo);

  return (
    <Avatar
      ref={ref}
      bg="transparent"
      borderRadius="8px"
      src={src}
      alt={subcategory?.name?.default}
      ignoreFallback={true}
      {...otherProps}
    />
  );
});

export default SubcategoryAvatar;
