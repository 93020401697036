import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { translate } from '../services/languageService';
import SubcategoryAvatar from './SubcategoryAvatar';

export default function SubcategoryPageTitle({
  category,
  subcategory,
  icon,
  lang,
  size,
  toggleFilterBar,
}) {
  return (
    <HStack w="full" h="full" spacing="6">
      <SubcategoryAvatar size={size} subcategory={subcategory} />
      <Box w="100%">
        <Text fontWeight="semibold" fontSize={size}>
          {translate(category.name, lang)}
          {' - '}
          {translate(subcategory.name, lang)}
        </Text>
      </Box>
      <Flex justify="end" w="100%">
        <IconButton
          aria-label="Toggle filter"
          icon={icon}
          justify="end"
          onClick={toggleFilterBar}
        />
      </Flex>
    </HStack>
  );
}
