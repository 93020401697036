import { createContext, useContext, useEffect, useState } from 'react';
import { REQUEST_STATUS, useRestRequest } from '../hooks/useRestRequest';
import { CategoriesUrl } from '../services/urlService';
import { mergeIncludedSubcategoriesIntoCategories } from '../services/mappingService';
import { useIdentityContext } from './IdentityContext';

const CategoriesContext = createContext();

const CategoriesProvider = ({ children }) => {
  const { isAuthenticated, authHeaders } = useIdentityContext();
  const { data, requestStatus, error } = useRestRequest(
    CategoriesUrl,
    authHeaders,
    true
  );
  const [categories, setCategories] = useState();

  useEffect(() => {
    // console.log(
    //   `use Categories Context effect ${requestStatus} AUTH? ${isAuthenticated}`
    // );
    if (!isAuthenticated) {
      setCategories(undefined);
      return;
    }
    switch (requestStatus) {
      case REQUEST_STATUS.SUCCESS:
        const adjustedCategories =
          mergeIncludedSubcategoriesIntoCategories(data);
        setCategories(adjustedCategories);
        break;
      default:
        setCategories(undefined);
    }
  }, [data, isAuthenticated, requestStatus]);

  const getCategory = path => {
    return categories?.find(c => c.path === path);
  };

  const value = {
    categories,
    getCategory,
    requestStatus,
    error,
  };

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};

const useCategoriesContext = () => {
  return useContext(CategoriesContext);
};

export { useCategoriesContext, CategoriesProvider };
