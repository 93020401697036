import { THEMES } from './themeService';

export const COLORMODES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const convertThemeToColorMode = (themeName, fallback) => {
  switch (themeName) {
    case THEMES.LIGHT:
      return COLORMODES.LIGHT;
    case THEMES.DARK:
      return COLORMODES.DARK;
    default:
      return fallback;
  }
};

export const getColorModePicture = (
  colorMode,
  item,
  fallbackLight,
  fallbackDark
) => {
  switch (colorMode) {
    case COLORMODES.LIGHT:
      return (
        item?.picture ?? item?.pictureDark ?? fallbackLight ?? fallbackDark
      );
    default:
      return (
        item?.pictureDark ?? item?.picture ?? fallbackDark ?? fallbackLight
      );
  }
};
