import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSelectableLocales,
  getValidLocale,
  LOCALES,
} from '../services/localizationService';
import {
  convertLocaleToLanguage,
  getSystemLanguage,
} from '../services/languageService';
import {
  useColorMode,
  useColorModeValue,
  useColorModePreference,
} from '@chakra-ui/react';
import { useLocalStorage } from './useLocalStorage';
import {
  COLORMODES,
  convertThemeToColorMode,
} from '../services/colorModeService';
import {
  createSelectableThemes,
  getValidTheme,
  THEMES,
} from '../services/themeService';
import _ from 'lodash';

const createSettingsFromUser = user => {
  const id = getIdFromUser(user);

  if (!user) {
    return { id, theme: THEMES.DEFAULT, locale: LOCALES.DEFAULT };
  }

  return {
    id,
    theme: getValidTheme(user.theme),
    locale: getValidLocale(user.locale),
  };
};

const getIdFromUser = user => {
  if (!user) {
    return 'guest';
  }
  return user.id;
};

const areEqualSettings = (a, b) => {
  return a.id === b.id && a.theme === b.theme && a.locale === b.locale;
};

const getUpdatedSettings = (
  newUserSettings,
  defaultUserSettings,
  allSettings
) => {
  const otherSettings = allSettings.filter(
    settings => settings.id !== newUserSettings.id
  );
  if (areEqualSettings(newUserSettings, defaultUserSettings)) {
    return [...otherSettings];
  }
  return [newUserSettings, ...otherSettings];
};

const useLocale = (userSettings, defaultUserSettings, setSettings) => {
  // hooks and state
  const [, i18n] = useTranslation('translation');
  const systemLanguage = getSystemLanguage(i18n);
  const currentLanguage = i18n.language;
  const userDefaultLanguage = convertLocaleToLanguage(
    defaultUserSettings.locale,
    systemLanguage
  );
  const expectedLanguage = convertLocaleToLanguage(
    userSettings.locale,
    userDefaultLanguage
  );

  // effects
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  useEffect(() => {
    if (expectedLanguage !== currentLanguage) {
      i18n.changeLanguage(expectedLanguage);
    }
  }, [currentLanguage, expectedLanguage, i18n]);

  // output
  const setLocale = locale => {
    setSettings(state => {
      userSettings.locale = locale;
      return getUpdatedSettings(userSettings, defaultUserSettings, state);
    });
    const language = convertLocaleToLanguage(locale, userDefaultLanguage);
    i18n.changeLanguage(language);
  };
  const locales = createSelectableLocales();
  const currentLocale = locales.find(
    item => item.locale === userSettings.locale
  );

  return { currentLocale, locales, setLocale };
};

const useTheme = (userSettings, defaultUserSettings, setSettings) => {
  // hooks and state
  const { setColorMode } = useColorMode();
  const systemColorMode = useColorModePreference();
  const currentColorMode = useColorModeValue(COLORMODES.LIGHT, COLORMODES.DARK);
  const userDefaultColorMode = convertThemeToColorMode(
    defaultUserSettings.theme,
    systemColorMode
  );
  const expectedColorMode = convertThemeToColorMode(
    userSettings.theme,
    userDefaultColorMode
  );

  // effects
  useEffect(() => {
    if (expectedColorMode !== currentColorMode) {
      setColorMode(expectedColorMode);
    }
  }, [currentColorMode, expectedColorMode, setColorMode]);

  // output
  const setThemeName = validThemeName => {
    setSettings(state => {
      userSettings.theme = validThemeName;
      return getUpdatedSettings(userSettings, defaultUserSettings, state);
    });
  };
  const themes = createSelectableThemes();
  const currentTheme = themes.find(item => item.name === userSettings.theme);
  const setTheme = newThemeName => {
    const newValidThemeName = getValidTheme(newThemeName);
    setThemeName(newValidThemeName);
    const colorMode = convertThemeToColorMode(
      newValidThemeName,
      userDefaultColorMode
    );
    setColorMode(colorMode);
  };

  return { currentTheme, themes, setTheme };
};

export const useSettings = user => {
  // settings
  const defaultUserSettings = createSettingsFromUser(user);
  const [settings, setSettings] = useLocalStorage('settings', [
    _.cloneDeep(defaultUserSettings),
  ]);
  const userSettings =
    settings.find(s => s.id === defaultUserSettings.id) ??
    _.cloneDeep(defaultUserSettings);

  const locale = useLocale(userSettings, defaultUserSettings, setSettings);
  const theme = useTheme(userSettings, defaultUserSettings, setSettings);
  return { ...theme, ...locale };
};
