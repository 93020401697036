import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCategoriesContext } from '../contexts/CategoriesContext';
import { REQUEST_STATUS } from './useRestRequest';

const createBreadcrumbsFromPath = async (path, categories) => {
  let result = [];
  if (!path) {
    return result;
  }
  const elements = path.split('/');
  if (!elements || elements.length === 0) {
    return result;
  }

  let category, subcategory;
  for (let i = 0; i < elements.length; i++) {
    const elementPath = elements[i];
    switch (i) {
      case 0: // category
        category = categories.find(c => c.path === elementPath);
        if (!category) {
          return result;
        }
        result.push({
          name: category.name,
          link: elementPath,
        });
        break;
      case 1: // subcategory
        subcategory = category.subcategories.find(
          sc => sc.path === elementPath
        );
        if (!subcategory) {
          return result;
        }
        result.push({
          name: subcategory.name,
          link: `${category.path}/${elementPath}`,
        });
        break;
      case 2: // item
        break;
      default:
        // unknown
        console.log(`unknown breadcrumb level ${i}`);
    }
  }
  return result;
};

export const useBreadcrumbs = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { categories, requestStatus } = useCategoriesContext();

  useEffect(() => {
    if (!categories && requestStatus === REQUEST_STATUS.LOADING) {
      return;
    }

    const updateBreadcrumb = async () => {
      const newBreadcrumbs = await createBreadcrumbsFromPath(
        location.pathname.substring(1),
        categories
      );
      setBreadcrumbs(newBreadcrumbs);
    };

    updateBreadcrumb();
  }, [location, categories, requestStatus]);

  return { breadcrumbs };
};
