import {
  Center,
  HStack,
  StackDivider,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translate } from '../../services/languageService';
import { REQUEST_STATUS } from '../../hooks/useRestRequest';
import CategoryAvatar from '../CategoryAvatar';
import NotFoundPage from './NotFoundPage';
import SubcategoryList from '../SubcategoryList';
import LoadingPage from './LoadingPage';
import { useCategoriesContext } from '../../contexts/CategoriesContext';

export default function CategoryPage({ minH }) {
  const { i18n } = useTranslation('translation');
  const { categoryPath } = useParams();
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const resizeCategoryAvatar = useBreakpointValue({ base: 'md', md: 'lg' });
  const resizeDescription = useBreakpointValue({ base: 'xs', md: 'sm' });
  const lang = i18n.language;
  const { getCategory, requestStatus } = useCategoriesContext();

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage minH={minH} />;
  }

  const category = getCategory(categoryPath);

  if (category === undefined) {
    return <NotFoundPage minH={minH} />;
  }

  return (
    <Center minH={minH} p="5">
      <VStack
        bg={categoriesBg}
        w={'950px'}
        maxW={'1100px'}
        boxShadow={'lg'}
        justify={{ base: 'center' }}
        align={{ base: 'center', md: 'center' }}
        display="flex"
        direction={['column', 'row']}
        borderWidth="1px"
        rounded={'2xl'}
        p="4"
        spacing="4"
        divider={<StackDivider />}
      >
        <VStack w="full" h="full">
          <HStack w={'full'} spacing="6">
            <CategoryAvatar category={category} size={resizeCategoryAvatar} />
            <Text fontWeight="semibold" fontSize={resizeCategoryAvatar}>
              {translate(category.name, lang)}
            </Text>
          </HStack>
          <Text fontSize={resizeDescription} color={'gray.500'} w={'full'}>
            {category.description === undefined
              ? '\u00A0'
              : translate(category.description, lang)}
          </Text>
        </VStack>
        <SubcategoryList category={category} lang={lang} />
      </VStack>
    </Center>
  );
}
