import { useState } from 'react';

const isWindow = () => {
  return typeof window === 'undefined';
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (isWindow()) {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (!isWindow()) {
        window.localStorage.setItem(
          key,
          JSON.stringify(valueToStore, (_, v) => {
            return v === undefined ? null : v;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
