import {
  Box,
  Collapse,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  useColorModeValue,
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Divider,
  Text,
} from '@chakra-ui/react';
import { MdClose, MdStarBorder, MdStar } from 'react-icons/md';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function AssetListFilterbar({
  filter,
  show,
  onToggle,
  allTags,
  filterIcon,
}) {
  const bg = useColorModeValue('gray.100', 'gray.700');
  const { t } = useTranslation('translation');

  const { search, tags, isFavorite } = filter.get();

  const tagsFactory = tag => {
    return (
      <MenuItemOption key={tag} value={tag}>
        {tag}
      </MenuItemOption>
    );
  };

  const TagButtonName = () => {
    switch (tags.length) {
      case 0:
        return t('subcategoryItems.tags');
      case 1:
        return tags[0];
      default:
        return `${tags[0]} +${tags.length - 1}`;
    }
  };

  return (
    <Box w="100%" h="100%">
      <Collapse mt={4} in={show} animateOpacity>
        <Flex w="full" bg={bg} borderRadius="8px">
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={filterIcon} />
            <Input
              border={'none'}
              _focus={{
                border: 'none',
              }}
              value={search}
              w="full"
              placeholder={t('subcategoryItems.filterPlaceholder')}
              onChange={event => {
                filter.updateSearch(event.target.value);
              }}
            />
          </InputGroup>
          <Spacer />
          <Box mr="2">
            <IconButton
              bg="transparent"
              fontSize="22px"
              value={isFavorite}
              icon={isFavorite ? <MdStar /> : <MdStarBorder />}
              onClick={() => filter.updateFavorite(!isFavorite)}
            />
          </Box>
          <Menu closeOnSelect={false}>
            <Box>
              <MenuButton
                as={Button}
                rightIcon={<FaChevronDown />}
                p="1"
                fontWeight={'md'}
                bg="transparent"
              >
                <Text fontWeight={tags.length > 0 ? 'semibold' : ''}>
                  <TagButtonName />
                </Text>
              </MenuButton>
            </Box>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                type="checkbox"
                value={tags}
                onChange={filter.updateTags}
              >
                {allTags.map(tagsFactory)}
              </MenuOptionGroup>
              <Divider />
              <MenuOptionGroup>
                <Box w="full" h="full" align="end">
                  <Button
                    m="1"
                    leftIcon={<MdClose />}
                    bg="transparent"
                    _hover={{
                      bg: 'none',
                    }}
                    onClick={() => filter.updateTags([])}
                  >
                    {t('subcategoryItems.clear')}
                  </Button>
                </Box>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <IconButton
            ml="2"
            bg="transparent"
            icon={<MdClose />}
            onClick={() => {
              filter.clear();
              onToggle();
            }}
          />
        </Flex>
      </Collapse>
    </Box>
  );
}
