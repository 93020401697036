import {
  Avatar,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
} from '@chakra-ui/react';
import { useIdentityContext } from '../contexts/IdentityContext';

// this is a temporary component for development to allow logging in with different users

export default function LoginModal({ isOpen, onClose }) {
  const { allUsers, setUserById, devKey, setDevKey } = useIdentityContext();
  const handleChange = event => setDevKey(event.target.value);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay opacity="0.5" />
      <ModalContent>
        <ModalHeader>Pick a User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <VStack w="100%">
              {allUsers
                ?.sort((a, b) =>
                  a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                )
                .map(user => {
                  return (
                    <Button
                      key={user.id}
                      w="100%"
                      onClick={() => {
                        setUserById(user.id);
                        onClose();
                      }}
                    >
                      <HStack w="100%">
                        <Avatar name={user.name} src={user.picture} size="xs" />
                        <Text>{user.name}</Text>
                      </HStack>
                    </Button>
                  );
                })}
            </VStack>
            <Text w="full" pl="16px" pt="12px">
              Development Key:
            </Text>
            <Input
              value={devKey}
              onChange={handleChange}
              placeholder="Dev Key"
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          This is a development tool, in production the UI will be hooked up to
          an identity provider.
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
