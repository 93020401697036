export const sortFieldAscending = (data, property) => {
  return data?.sort((a, b) => {
    if (Array.isArray(a[property])) {
      return sortSubArrayAscending(a[property], b[property]);
    }
    return sortSimpleFieldAscending(a[property], b[property]);
  });
};

export const sortFieldDescending = (data, property) => {
  return data?.sort((a, b) => {
    if (Array.isArray(a[property])) {
      return sortSubArrayDescending(a[property], b[property]);
    }
    return sortSimpleFieldDescending(a[property], b[property]);
  });
};

const sortSimpleFieldAscending = (a, b) => {
  return a.toLowerCase() > b.toLowerCase()
    ? 1
    : a.toLowerCase() < b.toLowerCase()
    ? -1
    : 0;
};

const sortSimpleFieldDescending = (a, b) => {
  return a.toLowerCase() < b.toLowerCase()
    ? 1
    : a.toLowerCase() > b.toLowerCase()
    ? -1
    : 0;
};

const sortSubArrayAscending = (a, b) => {
  a.sort((subA, subB) => {
    return sortSimpleFieldAscending(subA, subB);
  });
  b.sort((subA, subB) => {
    return sortSimpleFieldAscending(subA, subB);
  });
  return a.map(el => el.toLowerCase()) > b.map(el => el.toLowerCase())
    ? 1
    : a.map(el => el.toLowerCase()) < b.map(el => el.toLowerCase())
    ? -1
    : 0;
};

const sortSubArrayDescending = (a, b) => {
  a.sort((subA, subB) => {
    return sortSimpleFieldDescending(subA, subB);
  });
  b.sort((subA, subB) => {
    return sortSimpleFieldDescending(subA, subB);
  });
  return a.map(el => el.toLowerCase()) < b.map(el => el.toLowerCase())
    ? 1
    : a.map(el => el.toLowerCase()) > b.map(el => el.toLowerCase())
    ? -1
    : 0;
};
