import React from 'react';
import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { IdentityProvider } from '../contexts/IdentityContext';
import { SettingsProvider } from '../contexts/SettingsContext';
import ScrollToTop from './ScrollToTop';
import theme from '../theme';
import Header from './header/Header';
import Content from './Content';
import Footer from './Footer';
import LoginModal from './LoginModal';
import { CategoriesProvider } from '../contexts/CategoriesContext';

export default function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <IdentityProvider openDialog={onOpen}>
      <ChakraProvider theme={theme}>
        <CategoriesProvider>
          <SettingsProvider>
            <ScrollToTop />
            <Header minH="7vh" />
            <Content minH={{ base: '78vh', md: '83vh' }} />
            <Footer minH={{ base: '15vh', md: '10vh' }} />
            <LoginModal isOpen={isOpen} onClose={onClose} />
          </SettingsProvider>
        </CategoriesProvider>
      </ChakraProvider>
    </IdentityProvider>
  );
}
