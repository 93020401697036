import { LOCALES } from './localizationService';
import languagedetector from 'i18next-browser-languagedetector';

export const LANGUAGES = {
  ENGLISH: 'en',
  SPANISH: 'es',
  DUTCH: 'nl',
};

export const convertLocaleToLanguage = (locale, fallback) => {
  switch (locale) {
    case LOCALES.DUTCH:
      return LANGUAGES.DUTCH;
    case LOCALES.ENGLISH:
      return LANGUAGES.ENGLISH;
    case LOCALES.SPANISH:
      return LANGUAGES.SPANISH;
    default:
      return fallback;
  }
};

export const getValidLanguage = lang => {
  const supportedLngs = Object.values(LANGUAGES);
  return supportedLngs.find(l => l === lang) ? lang : supportedLngs[0];
};

export const translate = (text, culture) => {
  const t = typeof text;
  if (t === 'string') {
    return text;
  }

  const result = text[culture];
  return result !== undefined ? result : text.default;
};

export const getSystemLanguage = i18n => {
  const detection = {
    order: ['navigator'],
    excludeCacheFor: ['cimode', 'localStorage', 'cookie'],
  };
  const detector = new languagedetector(i18n.services, detection);
  const systemLangElements = detector.detect(['navigator']) + '';
  const systemLang = systemLangElements.split('-', 1)[0];
  return getValidLanguage(systemLang);
};
