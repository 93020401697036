import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import lightLogo from './../../images/buildsoft-small-logo.svg';
import darkLogo from './../../images/buildsoft-small-logo-dark.svg';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { translate } from '../../services/languageService';
import { useTranslation } from 'react-i18next';

export default function BreadcrumbDesktop() {
  const { breadcrumbs } = useBreadcrumbs();
  const logo = useColorModeValue(lightLogo, darkLogo);
  const { i18n } = useTranslation('translation');

  const itemFactory = item => {
    return (
      <BreadcrumbItem key={item.name.default}>
        <BreadcrumbLink fontSize="sm" as={NavLink} to={`/${item.link}`}>
          {translate(item.name, i18n.language)}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  };

  return (
    <>
      <Avatar
        bg="transparent"
        as={NavLink}
        to={'/'}
        src={logo}
        size="sm"
        borderRadius="4px"
      />
      <Breadcrumb mt="2" separator="/">
        {breadcrumbs.map(itemFactory)}
      </Breadcrumb>
    </>
  );
}
