import { Box, Grid, Text, VStack, Center } from '@chakra-ui/react';
import Logo from '../Logo';
import { useTranslation } from 'react-i18next';

import { useIdentityContext } from '../../contexts/IdentityContext';
import CategoryList from '../CategoryList';

export default function LandingPage({ minH }) {
  const { t } = useTranslation('translation');
  const { isAuthenticated } = useIdentityContext();

  if (isAuthenticated) {
    return (
      <Center minH={minH} p="5">
        <CategoryList width={{ md: '1350px' }} maxW="1350px" />
      </Center>
    );
  }

  return (
    <Box fontSize="xl" textAlign="center">
      <Grid minH={minH}>
        <VStack spacing={8} justifyContent="center">
          <Logo h="40vmin" pointerEvents="none" />
          <Text>{t('app.welcome')}</Text>
        </VStack>
      </Grid>
    </Box>
  );
}
