import { FaMoon, FaUserCog } from 'react-icons/fa';
import { MdWbSunny } from 'react-icons/md';

export const THEMES = {
  DEFAULT: 'default',
  DARK: 'dark',
  LIGHT: 'light',
};

export const createSelectableThemes = () => {
  const result = [
    { name: THEMES.DEFAULT, label: 'common.default', icon: FaUserCog },
    { name: THEMES.LIGHT, label: 'themeSwitcher.light', icon: MdWbSunny },
    { name: THEMES.DARK, label: 'themeSwitcher.dark', icon: FaMoon },
  ];

  return result;
};

export const getValidTheme = theme => {
  if (!theme) {
    return THEMES.DEFAULT;
  }

  if (!Object.values(THEMES).includes(theme)) {
    return THEMES.DEFAULT;
  }

  return theme;
};
